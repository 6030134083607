<template>
  <b-card no-body class="card-browser-states">
    <b-card-header>
      <div>
        <b-card-title class="mb-1">{{$t("Inactive Customers")}} </b-card-title>
        <!-- <b-card-text class="font-small-2"> Greater 30 days </b-card-text> -->
      </div>
      <div class="d-flex align-items-center">
        <feather-icon icon="CalendarIcon" size="16" />
        <v-select
          v-model="numberOfDays"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="numberOfDaysOptions"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50"
        />
        <b-dropdown
          variant="link"
          no-caret
          class="chart-dropdown"
          toggle-class="p-0"
          right
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              class="text-body cursor-pointer"
            />
          </template>
          <b-dropdown-item @click="onExport()">
            {{ $t("Export") }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </b-card-header>

    <!-- body -->
    <b-card-body>
      <div
        v-for="browser in revenueData"
        :key="browser.browserImg"
        class="browser-states"
      >
        <b-media vertical-align="center">
          <b-link
            :to="{ name: 'customers-view', params: { id: 1 } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ browser.vorname }} {{ browser.nachname }}
          </b-link>
          <small class="text-muted">{{ browser.emailadresse }}</small>
        </b-media>

        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1">
            {{ moment(String(browser.latest_date)).format("DD.MM.YYYY") }} |
            {{ moment().diff(moment(browser.latest_date), "days") }} days
          </span>
        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BDropdown,
  BDropdownItem,
  BLink,
  BFormInput,
  BFormDatalist,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { ref, onUnmounted, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import store from "@/store";
import reportStoreModule from "./reportStoreModule";
import _ from "lodash";
import moment from "moment";
import vSelect from "vue-select";
import axios from "@axios";

/* eslint-disable global-require */
const $trackBgColor = "#e9ecef";
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    BLink,
    VueApexCharts,
    flatPickr,
    BFormInput,
    BFormDatalist,
    vSelect,
  },
  props: {
    defaultRangePicker: {
      type: Array,
      required: true,
    },
  },
  computed: {
    moment: () => moment,
  },
  data() {
    return {
      numberOfDaysOptions: [10, 20, 30, 40, 50],
      numberOfDays: 30,
      rangePicker: "",
      chartData: [],
      chartClone: {},
      chartColor: [
        $themeColors.primary,
        $themeColors.warning,
        $themeColors.secondary,
        $themeColors.info,
        $themeColors.danger,
      ],
      chartSeries: [54.4, 6.1, 14.6, 4.2, 8],
      revenueData: [],
      browserData: [
        {
          //browserImg: require('@/assets/images/icons/google-chrome.png'),
          name: "Westbrooke Pointin",
          usage: "32434",
        },
        {
          //browserImg: require('@/assets/images/icons/mozila-firefox.png'),
          name: "Koral Staniforth",
          usage: "43433",
        },
        {
          //browserImg: require('@/assets/images/icons/apple-safari.png'),
          name: "Alfreda Lamba",
          usage: "3343",
        },
        {
          //browserImg: require('@/assets/images/icons/internet-explorer.png'),
          name: "Eadie Colquyte",
          usage: "2666",
        },
        {
          //browserImg: require('@/assets/images/icons/opera.png'),
          name: "Sibeal Bonds",
          usage: "1334",
        },
      ],
      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: "22%",
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: "always",
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: "round",
          },
        },
      },
    };
  },
  setup() {
    const REPORT_STORE_MODULE_NAME = "app-report";

    // Register module
    if (!store.hasModule(REPORT_STORE_MODULE_NAME))
      store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule);

    onUnmounted(() => {
      if (store.hasModule(REPORT_STORE_MODULE_NAME))
        store.unregisterModule(REPORT_STORE_MODULE_NAME);
    });
  },
  watch: {
    numberOfDays: function (newQuestion, oldQuestion) {
      this.debouncedFetchRevenueData();
    },
  },
  created: function () {
    this.debouncedFetchRevenueData = _.debounce(this.fetchRevenueData, 1000);
  },
  async mounted() {
    this.debouncedFetchRevenueData();
  },
  methods: {
    onExport() {
      var vm = this;

      axios({
        url: `/behandlung/export-inactive-customers`,
        method: "GET",
        responseType: "blob"
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        const [, filename] =
          response.headers["content-disposition"].split("filename=");
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    fetchRevenueData: function () {
      this.loaded = false;
      store
        .dispatch("app-report/fetchInactiveCustomer", {
          top: this.numberOfDays,
        })
        .then((response) => {
          let revenueData = response.data.data;
          this.revenueData = [];
          for (let i = 0; i < revenueData.data.length && i <5; i += 1) {
            //const chartClone = JSON.parse(JSON.stringify(this.chart));
            //chartClone.options.colors[0] = this.chartColor[i];
            //chartClone.series[0] = this.chartSeries[i];
            var data = revenueData.data[i];
            var days = moment().diff(moment(data.latest_date), "days");
            if (days > this.numberOfDays) {
              this.revenueData.push(revenueData.data[i]);
            }
          }

          this.loaded = true;
        })
        .catch((error) => {
          if (error && error.response.status === 404) {
            //this.chartData = null;
          }
        });
    },
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
