import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRevenue(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/behandlung/revenue', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTopCustomersByRevenues(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/behandlung/customers-by-revenues', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTopEmployeesByRevenues(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/behandlung/employees-by-revenues', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTopServicesByRevenues(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/behandlung/services-by-revenues', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInactiveCustomer(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/behandlung/inactive-customers', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}