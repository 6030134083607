<template>
  <b-row class="match-height">
    <b-col lg="12">
      <revenue-chart :defaultRangePicker="defaultRangePicker"/>
    </b-col>
    <!-- <b-col lg="12">
      <customers-chart />
    </b-col> -->
    <b-col lg="6">
      <top-customers-by-revenues-chart :defaultRangePicker="defaultRangePicker"/>
    </b-col>
    <!-- Browser States Card -->
    <b-col lg="6">
      <top-services-by-revenues :defaultRangePicker="defaultRangePicker"/>
    </b-col>
    <!--/ Browser States Card -->

    <b-col lg="6">
      <inactive-customers :defaultRangePicker="defaultRangePicker"/>
    </b-col>
    <!-- <b-col lg="6">
      <service-list />
    </b-col> -->
    <b-col lg="6">
      <top-employees-by-revenues-chart :defaultRangePicker="defaultRangePicker"/>
    </b-col>
  </b-row>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";
import RevenueChart from "./report/RevenueChartjsBarChart.vue";
import EcommerceMedal from "./report/EcommerceMedal.vue";
import EcommerceStatistics from "./report/EcommerceStatistics.vue";
import CustomersChart from "./report/CustomersChart.vue";
import { BRow, BCol } from "bootstrap-vue";
import TopCustomersByRevenuesChart from "./report/TopCustomersByRevenuesChart.vue";
import TopEmployeesByRevenuesChart from "./report/TopEmployeesByRevenuesChart.vue";
import TopServicesByRevenues from "./report/TopServicesByRevenues.vue";
import InactiveCustomers from "./report/InactiveCustomers.vue";
import ServiceList from "./services-list/ServiceList.vue";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    EcommerceMedal,
    EcommerceStatistics,
    RevenueChart,
    CustomersChart,
    TopCustomersByRevenuesChart,
    TopServicesByRevenues,
    ServiceList,
    InactiveCustomers,
    TopEmployeesByRevenuesChart
  },
  data() {
    return {
      data: {},
      defaultRangePicker: [],
    };
  },
  created() {
    // data
    // this.$http.get("/ecommerce/data").then((response) => {
    //   this.data = response.data;

    //   // ? Your API will return name of logged in user or you might just directly get name of logged in user
    //   // ? This is just for demo purpose
    //   const userData = getUserData();
    //   this.data.congratulations.name =
    //     userData.fullName.split(" ")[0] || userData.username;
    // });
    var today = new Date();
    var fromday = new Date();
    fromday.setDate(today.getDate() - 29);

    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "/" + mm + "/" + dd;

    dd = String(fromday.getDate()).padStart(2, "0");
    mm = String(fromday.getMonth() + 1).padStart(2, "0"); //January is 0!
    yyyy = fromday.getFullYear();

    fromday = yyyy + "/" + mm + "/" + dd;

    this.defaultRangePicker = [today, fromday];
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";

.flat-picker {
  width: 205px;
}
</style>
