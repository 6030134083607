import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTreatments(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/behandlung/more-info', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomerTreatments(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/treatment/customer-treatments', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}