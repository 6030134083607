<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ $t("Revenue") }}</b-card-title>
      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon icon="CalendarIcon" size="16" />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range', defaultDate: defaultRangePicker }"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
        <b-dropdown
          variant="link"
          no-caret
          class="chart-dropdown"
          toggle-class="p-0"
          right
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              class="text-body cursor-pointer"
            />
          </template>
          <b-dropdown-item @click="onExport()">
            {{ $t("Export") }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-bar-chart
        v-if="loaded"
        :height="400"
        :data="chartData"
        :options="chartjsData.latestBarChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import ChartjsComponentBarChart from "./charts-components/ChartjsComponentBarChart.vue";
import chartjsData from "./chartjsData";
import { ref, onUnmounted, watch } from "@vue/composition-api";
import store from "@/store";
import reportStoreModule from "./reportStoreModule";
import _ from "lodash";
import moment from "moment";
import axios from "@axios";
import { $themeColors } from '@themeConfig'

var chartColors =  {
        primaryColorShade: "#836AF9",
        yellowColor: "#ffe800",
        successColorShade: "#28dac6",
        warningColorShade: "#ffe802",
        warningLightColor: "#FDAC34",
        infoColorShade: "#299AFF",
        greyColor: "#4F5D70",
        blueColor: "#2c9aff",
        blueLightColor: "#84D0FF",
        greyLightColor: "#EDF1F4",
        tooltipShadow: "rgba(0, 0, 0, 0.25)",
        lineChartPrimary: "#3C4A41",
        lineChartDanger: "#ff4961",
        labelColor: "#6e6b7b",
        grid_line_color: "rgba(200, 200, 200, 0.2)",
      };

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    flatPickr,
    ChartjsComponentBarChart,
    BDropdown,
    BDropdownItem,
  },
  props: {
    defaultRangePicker: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartjsData,
      rangePicker: "",
      chartData: null,
      loaded: false,
      datasetColors: [
        "#7CC7CF",
        "#8DBF87",
        "#F184B0",
        "#74D4F2",
        "#EE666A",
        "#84ACD1",
        "#F39759",
      ],
      chartOptions: {
        // elements: {
        //   rectangle: {
        //     borderWidth: 2,
        //     borderSkipped: "bottom",
        //   },
        // },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          legend: {
            position: "top",
          },
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scales: {
          xAxes: [
            {
              // type: "time",
              // time: {
              //   unit: "day",
              // },
              // ticks: {
              //   source: "data",
              // },
              // displayFormats: {
              //   quarter: "MMM D",
              // },
              // display: true,
              // gridLines: {
              //   display: true,
              //   color: chartColors.grid_line_color,
              //   zeroLineColor: chartColors.grid_line_color,
              // },
              // scaleLabel: {
              //   display: false,
              // },
              // ticks: {
              //   fontColor: chartColors.labelColor,
              // },
              stacked: true,
              categoryPercentage: 0.5,
              barPercentage: 1
            },
          ],
          yAxes: [
            {
              // display: true,
              // gridLines: {
              //   color: chartColors.grid_line_color,
              //   zeroLineColor: chartColors.grid_line_color,
              // },
              // ticks: {
              //   min: 0,
              //   fontColor: chartColors.labelColor,
              // },
              stacked: true,
              ticks: {
                beginAtZero: true
              }
            },
          ],
        },
      },
    };
  },
  setup() {
    const REPORT_STORE_MODULE_NAME = "app-report";

    // Register module
    if (!store.hasModule(REPORT_STORE_MODULE_NAME))
      store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule);

    onUnmounted(() => {
      if (store.hasModule(REPORT_STORE_MODULE_NAME))
        store.unregisterModule(REPORT_STORE_MODULE_NAME);
    });
  },
  watch: {
    rangePicker: function (newQuestion, oldQuestion) {
      this.debouncedFetchRevenueData();
    },
  },
  created: function () {
    this.debouncedFetchRevenueData = _.debounce(this.fetchRevenueData, 1000);
  },
  async mounted() {
    //this.loaded = false;

    this.debouncedFetchRevenueData();
  },
  methods: {
    onExport() {
      var vm = this;
      var from = "";
      var to = "";
      var selectedRange = this.rangePicker && this.rangePicker.split("to");
      if (selectedRange.length == 1) return;
      if (selectedRange.length == 2) {
        from = selectedRange[0].trim();
        to = selectedRange[1].trim();
      }

      axios({
        url: `/behandlung/export-revenue`,
        method: "GET",
        responseType: "blob",
        params: {
          from: from,
          to: to,
        },
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        const [, filename] =
          response.headers["content-disposition"].split("filename=");
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    fetchRevenueData: function () {
      var vm = this;
      var from = "";
      var to = "";
      var selectedRange = this.rangePicker && this.rangePicker.split("to");
      if (selectedRange.length == 1) return;
      if (selectedRange.length == 2) {
        from = selectedRange[0].trim();
        to = selectedRange[1].trim();
      }

      this.loaded = false;
      store
        .dispatch("app-report/fetchRevenue", {
          from: from,
          to: to,
        })
        .then((response) => {
          let revenueData = response.data;
          let transformedData = _.map(revenueData.data, (val, index) => {
            let sortData = _.orderBy(val.data, (v) => moment(v.x), ['asc'])
            sortData = _.map(sortData, (v, i) => {
              return {
                x: moment(v.x),
                y: v.y,
              };
            });

            if (index == 0) {
              return {
                label: val.label,
                data: sortData,
                borderColor: "transparent",
              };
            } else {
              return {
                label: val.label,
                data: sortData,
                borderColor: this.datasetColors[index],
                backgroundColor: "transparent",
                
              };
            }
          });

          _.pullAt(transformedData, [0]);

          let labels = [];
          let transformedDataV2 = [];
          
          if(transformedData.length > 0) {
            labels = _.map(transformedData[0].data, (val, index) => {
         
              return val.x;
            });

            transformedDataV2 = _.map(transformedData, (val, index) => {
            let data = _.map(val.data, (v, i) => {
              return v.y
            });

            return {
                label: val.label,
                data: data,
                borderColor: this.datasetColors[index],
                backgroundColor: "transparent",
                type: 'bar',
              };
           });
          }

          vm.chartjsData.latestBarChart.options.scales.xAxes[0].time.unit = revenueData.data[0].unit;
          this.chartData = {
            labels: labels,
            datasets: transformedDataV2,
          };
          console.log(this.chartData);
          this.loaded = true;
        })
        .catch((error) => {
          if (error && error.response.status === 404) {
            this.chartData = null;
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
