import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import moment from 'moment'
import i18n from '@/libs/i18n'

export default function useTreatmentsList() {
  const refTreatmentListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'mitarbeiterName', label: 'Service', sortable: false },
    { key: 'revenue', sortable: true, formatter: val => `CHF 10000` },
  ]

  const perPage = ref(10)
  const totalTreatments = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refTreatmentListTable.value ? refTreatmentListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTreatments.value,
    }
  })

  const refetchData = () => {
    refTreatmentListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchTreatments = (ctx, callback) => {
    store
      .dispatch('app-treatment/fetchTreatments', {
        q: searchQuery.value,
        size: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const { treatments, total } = response.data

        callback(treatments)
        totalTreatments.value = total
      })
      .catch(() => {
        // toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: 'Error fetching treatments list',
        //     icon: 'AlertTriangleIcon',
        //     variant: 'danger',
        //   },
        // })
      })
  }

  return {
    fetchTreatments,
    tableColumns,
    perPage,
    currentPage,
    totalTreatments,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTreatmentListTable,
    refetchData,
  }
}